.feed-page {
    max-width: 1056px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.feed-categories-selector {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 4px;
    border: 1px solid #89718080;
    border-radius: 60px;
    /* width: 200px; */
    /* transition: width 500ms; */
}

.feed-categories-selector:hover {
    /* width: 300px; */
    /* transition: width 500ms; */
}

.feed-categories-selector a {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    white-space: nowrap;
    text-decoration: none;
    color: #897180;
    /* height: 14px;
    transition: height 8s ease-in-out;
    transform: translateX(0) */
}

.feed-filters {
    display: flex;
    align-items: center;
    gap: 24px;
}

/* .feed-categories-selector a:before {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border-radius: 99em;
    width: 100%;
    height: 100%;
    top: 0;
    transform: translateX(50%);
    transition: transform 8s ease-in;
    transform-origin: center right;
    background-color: #897180;
}   */

.feed-categories-selector a:first-child {
    padding-left: 24px;
}

.feed-categories-selector a:last-child {
    padding-right: 24px;
}

.feed-categories-selector a:hover {
    cursor: pointer;
}

.feed-categories-selector a.active {
    padding: 15px 24px;
    border-radius: 60px;
    background-color: #897180;
    color: #FCF9F8;
    height: 44px;
    /* transition: height 8s ease-in-out;
    transform: translateX(100%); */
}

button.filters-button {
    height: 54px;
    width: 54px;
}

button.filters-button:hover:not(:disabled) {
    background-color: #897180;
    border-color: #897180;
    color: #FCF9F8;
}

button.filters-button.active {
    background-color: #897180;
    border-color: #897180;
    color: #FCF9F8;
}

.feed-filters-container {
    display: flex;
    align-items: center;
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid rgba(137, 113, 128, 0.2);
}

.feed-filters-title {
    display: flex;
    align-items: center;
    margin-right: 24px;
    gap: 10px;
}

.feed-filters-title span {
    font-size: 18px;;
}

.feed-filters-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.feed-filters-buttons > div{
    display: flex;
    gap: 8px;
    padding: 12px 16px;
    border: 1px solid rgba(137, 113, 128, 0.5);
    border-radius: 60px;
}

.feed-filters-buttons > div:hover{
    cursor: pointer;
}

.feed-filters-buttons .active {
    background-color: #897180;
}

.feed-filters-buttons .active span {
    color: #FFFFFF;
}

.feed-filters-buttons span {
    font-size: 14px;
    font-weight: 500;
}

.feed-filters-buttons .filter-title {
    text-transform: uppercase;
}

.feed-filters-buttons .filter-number  {
    opacity: 0.5;
}

@media only screen and (max-width: 1024px) {
    button.filters-button {
       position: absolute;
       right: 0;
       top: 0;
       width: 40px;
       height: 40px;
    }

    .feed-categories-selector {
        gap: 0;
        width: 100%;
    }

    .feed-categories-selector a{
       flex-grow: 1;
        padding: 9px 16px;
        font-size: 11px;
    }

    .feed-categories-selector a.active {
        height: auto;
        padding: 14px 16px;
    }

    .feed-filters-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        margin-top: 16px;
        padding-bottom: 16px;
        padding-top: 0;
        border-top: 0;
        border-bottom: 1px solid rgba(137, 113, 128, 0.2);
    }

    .feed-filters-title svg {
        display: none;
    }

    .feed-filters-title span {
        font-size: 14px;
    }

    .feed-filters-buttons {
        flex-wrap: wrap;
    }

    .feed-filters-buttons span {
        font-size: 11px;
    }

    .feed-filters-buttons > div {
        padding: 10px 11px;
    }

    .feed-categories-selector a:first-child {
        padding-left: 16px;
    }
}