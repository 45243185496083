
input::placeholder{
  font-size: 14px;
  color: #897180;
  opacity: 0.5;
} 

.page-heading {
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 32px;
  font-size: 42px;
  font-weight: 600;
  color: #897180;
}

.main-heading {
  margin-bottom: 40px;
  text-align: center;
  font-size: 42px;
  font-weight: 600;
}
.onboarding-description {
  text-align: center;
  line-height: 20px;
  max-width: 500px;
}
.question-card {
  max-width: 500px;
  box-shadow: 0px 2px 2px 0px #89718033;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  margin-bottom: 32px;
}
.question-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  background-color: #FAF6F3;
  padding: 14px 24px;
  color: #897180;
}
.question-card-header > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;
  max-width: 314px;
}
.question-card-header h2 {
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 400;
  overflow-wrap: anywhere;
  line-height: 24px;
}
.question-card-header p {
  font-size: 13px;
  line-height: 1.2;
}
.question-card-body {
  padding: 40px;
}
.question-card-section:not(:last-of-type) {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(137, 113, 128, 0.1);
}


.page-card {
  border-radius: 12px;
  overflow: hidden;
}

.page-card-header {
  background-color: #FAF6F3;
  padding: 20px 24px;
  color: #897180;
}

.page-card-header h2{ 
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 400;
  overflow-wrap: anywhere;
  line-height: 24px;
}

.question-card-header {
  padding-inline: 20px;
}

.question-card-header > div {
  padding: 6px 0;
}

.question-card-header h2 {
  margin-bottom: 12px;
}

.question-card-header img {
  max-height: 76px;
}

.page-card-body {
  display: flex;
  background-color: white;
}

.question-option {
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.question-option button {
  border-radius: 60px !important;
  border: 1px solid rgba(137, 113, 128, 0.5) !important;
  margin-left: 0 !important;
  font-weight: 500;
  color: #897180;
  text-transform: none;
  line-height: 1.2;
}
.slider-container {
  display: flex;
  align-items: center;
}
.slider-container div:first-child {
  flex-grow: 1;
  height: 24px;
  display: flex;
  align-items: center;
}
.slider-container .slider {
  padding-right: 24px;
  margin-right: 14px;
  border-right: 1px solid rgba(137, 113, 128, 0.1);
}
.slider-value {
  font-size: 28px;
  font-weight: 500;
  width: 40px;
  text-align: center;
}

.slider-value input + span {
  display: none;
}

.slide-content {
  z-index: 1
}

.onboarding-header {
  display: flex;
  align-items: center;
  background-color: #F4EEEB;
  height: 80px;
  padding: 0 40px;
}
.onboarding-header button {
  color: #897180;
  padding: 0;
  background-color: transparent;
  border: none;
  text-transform: none;
  font-weight: 500;
}
.onboarding-header button:hover {
  background-color: transparent;
  color: #4e3846;
  border: none;
}
/* .onboarding-header.welcome .skip-container {
  display: none;
} */
.progress {
  margin-left: 32px;
  flex-grow: 1;
}
.skip-container {
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(137, 113, 128, 0.3);
  margin-left: 32px;
  padding-left: 32px;
  height: 40px;
}
.swiper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 80px);
  flex-grow: 1;
}
.swiper-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  z-index: 1;
  max-width: 500px;
  margin: 0 auto;
}

.onboarding-navigator button:first-child {
  transform: scaleX(-1);
}

.onboarding-navigator button:first-child:hover {
  background-color: #897180;
  border-color: #897180;
  color: #FFFFFF;
}

.onboarding-navigator button:last-child {
  width: 320px;
}

.profile-questions-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 0 40px;
}

.profile-questions-container:before {
  content: '';
  background-color: #FEAC8C;
  width: 830px;
  height: 830px;
  border-radius: 300px;
  filter: blur(70px);
  position: absolute;
  z-index: 0;
  right: -35%;
  bottom: -50%;
  opacity: 0.3;
}

.profile-questions-container:after {
  content: '';
  background-color: #D2877D;
  width: 830px;
  height: 750px;
  border-radius: 300px;
  filter: blur(70px);
  position: absolute;
  z-index: 0;
  right: 65%;
  bottom: -60%;
  opacity: 0.3;
}

.welcome-image-container {
  width: 500px;
  height: 270px;
  display: block;
  overflow: hidden;
  border-radius: 208px;
  margin: 0 auto 22px;
}
.welcome-image-container img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: 100%;
}


@media only screen and (max-width: 1280px) {
  .onboarding-header {
    padding: 0 20px;
  }
  .onboarding-header img {
    width: 64px;
  }
  .onboarding-description {
    margin-bottom: 32px;
  }
  .question-card-section:not(:last-of-type) {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
  .question-option button {
    font-size: 12px;
  }
  .question-card-body {
    padding: 24px;
  }
  .skip-container {
    margin-left: 16px;
    padding-left: 16px;
  }
  .progress {
    margin-left: 16px;
  }

  .welcome-image-container {
    height: 138px;
    width: 317px;
  }

}

@media only screen and (max-width: 1024px) {
  .page-card-body {
    flex-direction: column;
    padding: 20px;
  }

  .page-card-header h2 {
    font-size: 22px;
  }

  .page-card-header p {
    font-size: 13px;
  }

  .page-heading {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .profile-questions-container {
    padding: 32px 20px 90px;
  }

  .onboarding-header {
    height: 48px;
  }

  .onboarding-navigator {
    justify-content: space-between;
  }

  .onboarding-navigator button:last-child {
    width: auto
  }

  .skip-container { 
    height: 28px;
  }

  .slider-container .slider {
    border-right: 0;
    padding-right: 0;
    margin-right: 20px;
  }

  .slider-value {
    font-size: 20px;
    width: 30px;
  }

  .profile-questions-container:before {
    background-color: #FEEBD6;
    width: 750px;
    border-radius: 300px;
    filter: blur(70px);
    position: absolute;
    z-index: 0;
    height: 350px;
    right: 35%;
    top: 16%;
    right: 0;
    opacity: 0.6;
  }

  .profile-questions-container:after {
    background-color: #FEAC8C;
    width: 750px;
    border-radius: 300px;
    filter: blur(90px);
    position: absolute;
    z-index: 0;
    height: 380px;
    left: 60%;
    bottom: 0;
    top: 20%;
    right: 0;
    opacity: 0.3;
  }
}


@media only screen and (max-width: 600px) {
  .onboarding-navigator {
    margin: 0;
  }
}