
.video-details-bar {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 24px;
    border-top: 1px solid #C2BCB8;
    border-bottom: 1px solid #C2BCB8;
    height: 80px;
}

.author, .categories {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 18px 16px;
}

.author {
    flex-shrink: 0;
    gap: 16px;
    border-right: 1px solid #C2BCB8;
}

.author span{
   font-size: 18px;
}

.categories {
    gap: 16px;
    flex-grow: 1;
    border-right: 1px solid #C2BCB8;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.categories::-webkit-scrollbar{
    display: none;
  }

.categories span { 
    padding: 13px 16px;
    border: 1px solid #897180;
    border-radius: 60px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.video-actions {
    padding: 18px 16px;
}

.video-actions div > div:first-child {
   border: 0;
   padding-right: 0;
   justify-content: flex-start;
   margin-right: 8px;
}

.related-video-priview {
    max-height: 130px;
    overflow: hidden;
}

.related-video-priview img{
    background-size: cover;
    background-position: center;
    width: 100%;
}

.related-video-info  {
    padding: 12px 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.related-video-info h4 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
}

.related-video-info span {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    color: #897180;
    opacity: 0.7;
}

.related-video-info-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:auto
}

.premium-small-tag {
    padding: 4px 8px;
    border-radius: 60px;
    background-color: #FFBE97;
}

.premium-small-tag svg {
    width: 16px;
    height: 13px;
}

.video-page-header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.video-page-header h1 {
    margin-left: 24px;
    padding-left: 24px;
    border-left: 1px solid #DFCFCA;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.video-page-header button {
    width: 48px;
    height: 48px;
    transform: scaleX(-1);
   flex-shrink: 0;
}

.video-page-header button:hover {
   background-color: #897180;
   color: #FFFFFF;
   border-color: #897180;
}

.video-page-header button svg{
   height: 20px;
   width: 20px;
}

.video-page-header + div:hover{
  cursor: pointer;
}

@media only screen and (max-width: 1024px) { 
    .video-page-header h1 {
        font-size: 24px;
    }

    .video-page-header button {
       width: 40px;
       height: 40px;
    }

    .author{
        flex-grow: 1}

    .categories  {
    display: none;
    }
}

