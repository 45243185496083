.modal-header {
    padding: 26px 24px;
    border-radius: 12px 12px 0 0;
    background-color: #FAF6F3;
}

.modal-header h5 {
    font-size: 24px;
    font-weight: 400;
}

.avatar-modal-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 24px;
}

.modal-upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -24px;
    margin-top: 24px;
}

.modal-upload-button button{
    background-color: transparent;
    border: 1px dashed rgb(165 152 158);
    border-radius: 4px;
    padding: 0;
}

.modal-upload-button button:hover{
    border: 1px dashed rgb(165 152 158)!important;
    border-radius: 4px;
}

.modal-profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
}