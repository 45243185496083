.onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: #F4EEEB;
}
.welcome-page {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 20px 40px;
  color: #897180;
  height: 100%;
  flex-grow: 1;
}
.welcome-page .onboarding-step {
  padding-left: 40px;
  margin-left: 40px;
  border-left: 1px solid #E1D5CE;
}
.welcome-page:before {
  content: '';
  background-color: #FEEBD6;
  width: 750px;
  height: 750px;
  border-radius: 300px;
  filter: blur(70px);
  position: absolute;
  z-index: 0;
  right: 85%;
}
.welcome-page:after {
  content: '';
  background-color: #FEAC8C;
  width: 750px;
  height: 750px;
  border-radius: 300px;
  filter: blur(90px);
  position: absolute;
  z-index: 0;
  right: -20%;
  bottom: -73%;
  opacity: 0.4;
}
.module-description {
  display: flex;
  align-items: center;
  justify-content: center;
}

.module-description button{
  width: 48px;
  height: 48px;
  transform: scaleX(-1);
  flex-shrink: 0;
  margin-bottom: 40px;
}

.module-description button + h2{
  margin-left: 24px;
  padding-left: 24px;
  border-left: 1px solid #DFCFCA;
}

.onboarding-step {
  z-index: 1;
  color: #897180;
}
.onboarding-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.onboarding-form > button {
  width: 320px;
}
.welcome-section {
  z-index: 1;
}
.welcome-section h2 {
  margin-bottom: 8px;
  line-height: 52px;
}

.welcome-signature {
  margin: 12px auto 20px;
  display: block;
}
.welcome-heading {
  text-align: center;
  font-size: 42px;
  line-height: 50px;
  font-weight: 600;
}
.welcome-card-header > div {
  max-width: none;
}
@media only screen and (max-width: 1280px) {
  .welcome-page {
    flex-direction: column;
    padding: 20px 16px 90px;
  }
  .welcome-page .onboarding-step {
    border: none;
    padding: 0;
    margin: 0;
  }

  .onboarding-step > h2{
    display: none;
  }

  .welcome-page:before {
    content: '';
    height: 650px;
    right: 35%;
    top: 4%;
  }

  .welcome-page:after{
    content: '';
    height: 580px;
    left: 60%;
    bottom: 0;
    top: 12%;
    right: 0;
    opacity: 0.3;
  }

  .welcome-heading {
    font-size: 38px;
    font-weight: 400;
  }
  .onboarding-form > button {
    width: 100%;
  }

  .welcome-section h2 {
    font-size: 38px;
    font-weight: 400;
  }

  .welcome-card-header {
    display: none;
  }

}

@media only screen and (max-width: 680px) {
  .module-description {
    justify-content: flex-start;
  }
  
  .module-description button{ 
    width: 40px;
    height: 40px;
    margin-bottom: 30px;
  }
  
  /* .module-description h2{ 
    display: none;
  } */
}